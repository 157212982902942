import "./App.css";
import { createContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Component/Header";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import CoursesPage from "./Component/CoursesPage";
import Login from "./Component/Login";
import Player from "./Component/Player";
import VideoPage from "./Component/VideosPage";
import TeachersPage from "./Component/TeachersPage";
import TeacherDetails from "./Component/TeacherDetails";
export const AppContext = createContext();
function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [player, setPlayer] = useState(null);
  return (
    <>
      <AppContext.Provider
        value={{
          userData: [userInfo, setUserInfo],
          playerInfo: [player, setPlayer],
        }}
      >
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index path="/" element={<Home />}></Route>
            <Route path="/courses" element={<CoursesPage />}></Route>
            <Route
              path="/videos"
              element={<VideoPage pageName={"videos"} />}
            ></Route>
            <Route
              path="/cast"
              element={<VideoPage pageName={"casts"} />}
            ></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/teacher" element={<TeachersPage />}></Route>
            <Route path="/teacher/:id" element={<TeacherDetails />}></Route>
          </Routes>
          <Player />
          <Footer />
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
}
export default App;
