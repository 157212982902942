import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import NavsList from "./NavsList";
import "../Style/teacherPage.css"
import { scrollToTop } from "./Methods";
import CourseItem from "./CourseItem";
import PagePagintaion from "./PagePagination";
export default function TeacherDetails(){
    const param = useParams();
    const [data,setData] = useState({
        teacherName : "",
        teacherData : "",
        totalPage : 0,
    });
    const [isLoading,setIsLoading] = useState({
        filter : true,
        data : false
    });
    const [fullAbout,setFullAbout] = useState(false);
    const [searchParam,] = useSearchParams();
    const pageNumber = useRef(1);
    const teacherCourseSection = useRef(null);
    useEffect(() => {
        scrollToTop();
        fetchData();
    }, []);
    function fetchData(){
        setTimeout(() => {
            setData(
                {
                    teacherName:"محمد راد",
                    teacherData :{
                        name : "محمد راد",
                        cover : "/image/teacherImg.webp",
                        expert : "برنامه نویس فرانت ، ری اکت ، نکست js",
                        blogNumber : 10,
                        courseNumber : 15,
                        podcastNumber : 1,
                        videosNumber :5,
                        about : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
                        teacherCourse :[
                            {
                                url : "/course/254",
                                title : "نصب یونیتی ۶",
                                teacher : "مدائنی",
                                teacherId : "25",
                                duration : "۰۱:۲۰:۰۰",
                                price : 0,
                                cover : "/image/courseImg.webp",
                                discount : 40,
                            },
                            {
                                url : "/course/254",
                                title : "نصب یونیتی ۶",
                                teacher : "مدائنی",
                                teacherId : "25",
                                duration : "۰۱:۲۰:۰۰",
                                price : 1450000,
                                cover : "/image/courseImg.webp",
                                discount : 0,
                            },
                            {
                                url : "/course/254",
                                title : "لایو مارکتینگ",
                                teacher : "مدائنی",
                                teacherId : "25",
                                duration : "۰۱:۲۰:۰۰",
                                price : 0,
                                cover : "/image/courseImg.webp",
                                discount : 0,
                            },
                            {
                                url : "/course/254",
                                title : "نصب یونیتی ۶",
                                teacher : "مدائنی",
                                teacherId : "25",
                                duration : "۰۱:۲۰:۰۰",
                                price : 0,
                                cover : "/image/courseImg.webp",
                                discount : 40,
                            },
                        ]
                    },
                    totalPage : 10,
                }
            );
            setIsLoading({
                filter : false,
                data : false
            });
        }, 2500);
    }
    function changePage(newPageNumber){
        if(!teacherCourseSection.current){
            teacherCourseSection.current = document.getElementById("teacherCourseSection");
        }
        teacherCourseSection.current.scrollIntoView();
        setIsLoading({
            ...isLoading,data : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    return <>
        <NavsList data={[{name:"اساتید",link:"/teacher"},{name:data.teacherName,link:"/teacher"}]} />
        {
            data.teacherData ? 
            <>
                <section className="grid teacherInfoSection container">
                    <div className="flex flexCol">
                        <div className="flex flexCol teacherImgDiv">
                            <img src={data.teacherData.cover} alt={data.teacherData.name} className="teacherDetailsImg" />
                        </div>
                        <div className="flex flexCol">
                            <h1 className="faFont textSize12 textClr3 teacherDetailsName">
                            {
                                data.teacherData.name
                            }
                        </h1>
                        <p className="faFont textSize9 textClr7 teacherJobText">
                            {
                                data.teacherData.expert
                            }
                        </p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="grid teacherSummGrid">
                        <div className="teacherSummItem flex flexCol alignCenter rel">
                            <p className="faFont summNumber textClr3 textSize16">
                            {
                                data.teacherData.blogNumber
                            }
                            </p>
                            <p className="faFont textSize1 textClr7 summText">
                                مقالات
                            </p>
                        </div>
                        <div className="teacherSummItem flex flexCol alignCenter rel">
                            <p className="faFont summNumber textClr3 textSize16">
                            {
                                data.teacherData.courseNumber
                            }
                            </p>
                            <p className="faFont textSize1 textClr7 summText">
                                دوره ها
                            </p>
                        </div>
                        <div className="teacherSummItem flex flexCol alignCenter rel">
                            <p className="faFont summNumber textClr3 textSize16">
                            {
                                data.teacherData.videosNumber
                            }
                            </p>
                            <p className="faFont textSize1 textClr7 summText">
                                ویدیوها
                            </p>
                        </div>
                        <div className="teacherSummItem flex flexCol alignCenter rel">
                            <p className="faFont summNumber textClr3 textSize16">
                            {
                                data.teacherData.podcastNumber
                            }
                            </p>
                            <p className="faFont textSize1 textClr7 summText">
                                پادکست ها
                            </p>
                        </div>
                    </div>
                </section>
                <section className="flex flexCol container aboutSection">
                    <h2 className="faFont textSize14 aboutTitle flex alignCenter">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M319.4 320.6L224 416l-95.4-95.4C57.1 323.7 0 382.2 0 454.4v9.6c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-9.6c0-72.2-57.1-130.7-128.6-133.8zM13.6 79.8l6.4 1.5v58.4c-7 4.2-12 11.5-12 20.3 0 8.4 4.6 15.4 11.1 19.7L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.3C51.4 175.4 56 168.4 56 160c0-8.8-5-16.1-12-20.3V87.1l66 15.9c-8.6 17.2-14 36.4-14 57 0 70.7 57.3 128 128 128s128-57.3 128-128c0-20.6-5.3-39.8-14-57l96.3-23.2c18.2-4.4 18.2-27.1 0-31.5l-190.4-46c-13-3.1-26.7-3.1-39.7 0L13.6 48.2c-18.1 4.4-18.1 27.2 0 31.6z"></path></svg>
                        درباره ی مدرس
                    </h2>
                    <div className={fullAbout ? "flex flexCol aboutBodyDiv full" : "flex flexCol aboutBodyDiv"}>
                        <p className="faFont textSize1 textClr5 aboutMainText">
                            {
                                data.teacherData.about
                            }
                        </p>
                        <div className="aboutBg"></div>
                        <div className="aboutBg2"></div>
                        <button className="aboutFullBtn faFont textSize9 textClr3" onClick={()=>setFullAbout(true)}>
                            مشاهده بیشتر
                        </button>
                    </div>
                </section>
                <section className="flex flexCol teacherCourseSection container" id="teacherCourseSection">
                    <h3 className="faFont textClr3 teacherCourseTitle flex alignCenter">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z"></path></svg>
                        دوره های درس
                    </h3>
                    <div className="grid teacherCourseGrid">
                        {
                            data.teacherData.teacherCourse.map((item,index)=>{
                                return <CourseItem data={item} key={index} />
                            })
                        }
                    </div>
                    <PagePagintaion data={{totalNumber:data.totalPage,
                        changePage : changePage, currentPage:pageNumber}} />
                </section>
            </>
            : <div className="flex flexCol teacherInfoSectionLoading">
                
            </div>
        }
    </>
}